import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

export const Header = () => {
  const [state, setstate] = useState({
    slide: 0,
    ossUrl: '',
  })

  useEffect(() => {
    setstate({
      ...state,
      ossUrl: window['OSS_URL'],
    })
  }, [])

  const { ossUrl } = state

  return (
    <header>
      <Link to="/" className="navbar-brand">
        <img src="/img/logo.png" alt="Tango" className="text-logo" />
      </Link>
      <nav className="nav" role="navigation">
        <Link to="/" className="btn btn-link">
          Home
        </Link>
        <Link to="/contact" className="btn btn-link">
          Contact us
        </Link>
        <a className="btn btn-primary" href={ossUrl}>
          Login
        </a>
      </nav>
    </header>
  )
}
