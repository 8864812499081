export default class Helper {
  static syncHeights(nodeSelector) {
    setTimeout(() => {
      const nodes = Array.from(document.querySelectorAll(nodeSelector))
      nodes.forEach(node => (node.style.minHeight = ``))
      const maxHeight = nodes.reduce(
        (acc, value) => Math.max(value.clientHeight, acc),
        0
      )
      nodes.forEach(node => (node.style.minHeight = `${maxHeight}px`))
    }, 200)
  }
}
