import React from 'react'
import PropTypes from 'prop-types'

import trophy from '../../assets/images/showcase-trophy.svg'
import rocket from '../../assets/images/showcase-rocket.svg'
import danger from '../../assets/images/critical-danger.png'
import transferYourExistingNumber from '../../assets/images/transfer-your-existing-number.svg'
import support from '../../assets/images/support-365-days-a-year.svg'
import network from '../../assets/images/high-speed-network.svg'
import noLockInContract from '../../assets/images/no-lock-in-contract.svg'

export const Showcase = ({ variation = 1 }) => (
  <section className="showcase showcase-why-accord">
    <div className="container">
      {variation === 2 ? (
        <h2 className="banner-title">Why Commtel?</h2>
      ) : (
        <h1 className="banner-title">
          Why Commtel {{ 3: 'Mobile', 1: 'nbn™' }[variation]}?
        </h1>
      )}
    </div>
    <div className="showcase-container showcase-detail">
      <div className="row">
        <p>
          Commtel is a leading provider of fixed broadband services in
          Australia, committed to delivering excellence in connectivity. With an
          extensive and highly valuable infrastructure, we offer a diverse range
          of products backed by world-class fiber networks, designed to meet the
          evolving needs of users across the country.{' '}
        </p>

        <p className="text-red">
          Our Broadband Services: Empowering Your Digital Lifestyle{' '}
        </p>

        <p>
          Commtel takes pride in its ability to offer an array of broadband
          services, catering to a wide spectrum of users, from homeowners to
          renters and everyone in between. Our commitment to providing
          innovative solutions that enhance the digital lives of our customers
          has been a driving force in our mission.{' '}
        </p>

        <p className="text-red">A Culture of Caring and Customer-Centricity </p>

        <p>
          At Commtel, we believe that the heart of our success lies in our
          culture of caring and a relentless pursuit of providing the best
          customer experience and service. We are not just a service provider;
          we are a partner in your digital journey. Our team is dedicated to
          setting an industry standard for value and care. We understand that
          your digital connectivity is not just a utility but an essential part
          of your daily life.
        </p>

        <p className="text-red">
          Reliability, Speed, and Affordability: Our Promise to You{' '}
        </p>

        <p>
          Our extensive network infrastructure, the dedication of our people,
          and our unwavering commitment to delivering excellence have allowed us
          to provide our customers with the most reliable, fast, and
          cost-effective broadband services in the market. We understand that in
          today's fast-paced, interconnected world, reliable internet access is
          non-negotiable. Commtel's promise is to empower you with seamless,
          high-speed connectivity without breaking the bank.{' '}
        </p>

        <p className="text-red">Join the Commtel Community </p>

        <p>
          At Commtel, we invite you to be a part of a community that values
          connectivity, innovation, and customer satisfaction. Explore the
          digital world with confidence, knowing that you have a trusted partner
          in Commtel, dedicated to delivering top-tier broadband services that
          cater to your unique needs.{' '}
        </p>

        <p>
          Discover the Commtel difference and experience the future of
          connectivity. Your digital journey begins here.
        </p>
      </div>
    </div>
    <div className="showcase-container">
      <div className="row">
        <div className="col-md-4">
          {
            {
              1: (
                <div className="trophy showcase-item">
                  <img src={trophy} alt="icon trophy" />
                  <h2>High-performance speeds</h2>
                  <p>
                    In a world where every second counts, high-performance
                    speeds are the key to staying ahead. Unleash the full
                    potential of your internet connection and embrace a digital
                    experience without boundaries. Say hello to faster
                    downloads, smoother uploads, and seamless connectivity that
                    keeps pace with your digital lifestyle.
                  </p>
                  <p>
                    Make the switch to high-performance speeds, and experience
                    the internet as it should be – fast, reliable, and ready to
                    take on anything the digital world throws at you
                  </p>
                </div>
              ),
              2: (
                <div className="trophy showcase-item">
                  <img src={trophy} alt="icon mobile" />
                  <h2>Designed to be easy</h2>
                  <p>
                    Simple plans tailored for home and small business. Sign up
                    online in minutes.
                  </p>
                </div>
              ),
              3: (
                <div className="trophy showcase-item">
                  <img src={network} alt="icon network" />
                  <h2>
                    High-speed <br />
                    network
                  </h2>
                  <p>
                    Get high speed data and call connections with great coverage
                    across 97.2% of Australia's population. Powered by the Optus
                    4G network.
                  </p>
                </div>
              ),
            }[variation]
          }
        </div>
        <div className="col-md-4">
          {
            {
              1: (
                <div className="speed showcase-item">
                  <img src={rocket} alt="icon rocket" />
                  <h2>No limits</h2>
                  <p>
                    Unlimited data is not just about convenience; it's about
                    enabling a truly limitless online experience. There's no
                    need to monitor your usage or schedule your online
                    activities around data caps or peak hours. Your online world
                    is open and ready for you to explore.
                  </p>
                  <p>
                    So, whether you're a professional striving for productivity,
                    a gamer chasing victory, or someone simply looking to make
                    the most of your online time, unlimited data offers the
                    ultimate online freedom. Unleash your full online potential
                    and embrace the digital world without constraints or
                    limitations.
                  </p>
                  <p>
                    Make the switch to unlimited data today, and experience a
                    world of endless possibilities, where the only limit is your
                    imagination.
                  </p>
                </div>
              ),
              2: (
                <div className="speed showcase-item">
                  <img src={network} alt="icon sim card" />
                  <h2>High speed network</h2>
                  <p>
                    Keep connected to what matters most with our fast, reliable
                    network.
                  </p>
                </div>
              ),
              3: (
                <div className="speed showcase-item">
                  <img src={noLockInContract} alt="icon no lock in contract" />
                  <h2>
                    No lock-in <br />
                    contract
                  </h2>
                  <p>
                    All our mobile plans are month-to-month so you don't have to
                    worry about complex contracts or hidden fees.
                  </p>
                </div>
              ),
            }[variation]
          }
        </div>
        <div className="col-md-4">
          {
            {
              1: (
                <div className="support showcase-item">
                  <img src={support} alt="icon support" />
                  <h2>Priority Support</h2>
                  <p>
                    In the ever-evolving digital landscape, having a reliable
                    support system is essential. Priority Support ensures that
                    you have peace of mind, knowing that help is always within
                    reach. It's not just about addressing problems; it's about
                    providing you with the confidence to explore and maximize
                    your digital world.
                  </p>
                  <p>
                    Make Priority Support your essential companion in the
                    digital age. Whether you're a professional, a gamer, or
                    someone seeking dependable technical assistance, you can
                    count on us to be there when you need us. With Priority
                    Support, you're never alone on your digital journey.
                  </p>
                </div>
              ),
              2: (
                <div className="support showcase-item">
                  <img src={support} alt="icon support" />
                  <h2>24/7 technical support</h2>
                  <p>
                    We’re available 24 hours a day, 7 days a week in case
                    something goes wrong.
                  </p>
                </div>
              ),
              3: (
                <div className="support showcase-item">
                  <img
                    src={transferYourExistingNumber}
                    alt="icon transfer your existing number"
                  />
                  <h2>
                    Transfer your <br />
                    Existing number
                  </h2>
                  <p>
                    It's easy to bring your existing mobile number over from
                    your current telco provider or get a new one.
                  </p>
                </div>
              ),
            }[variation]
          }
        </div>
      </div>
    </div>
  </section>
)

Showcase.propTypes = {
  variation: PropTypes.number,
}
