import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import { Header } from './components/Header'
import { Footer } from './components/Footer'

import '../assets/scss/index.scss'
import livechat from '../services/livechat'

export const Layout = props => {
  useEffect(() => {
    livechat()
  }, [])

  const { children } = props

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="grid-content">
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[{ name: 'description', content: 'Tango Mobile' }]}
          >
            <link
              rel="apple-touch-icon-precomposed"
              sizes="180x180"
              href="/iconsapple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/icons/android-chrome-192x192.png"
              sizes="192x192"
            />
            <link
              rel="icon"
              type="image/png"
              href="/icons/android-chrome-512x512.png"
              sizes="512x512"
            />
            <meta name="application-name" content="Tango" />
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta
              http-equiv="Content-Security-Policy"
              content="upgrade-insecure-requests"
            />
          </Helmet>
          <Header />
          <div className="main-content">{children}</div>
          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
