import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'

export const Plan = ({
  id,
  name,
  highlighted,
  banner,
  blurb,
  price,
  promotionalPricing,
  minimumTotalCost,
  optionalPlanCostCopy,
  cisUrl,
  typicalEveningSpeeds,
}) => (
  <div className="plan-wrapper">
    <div className={`plan ${highlighted ? 'plan-highlighted' : ''}`}>
      {highlighted && (
        <div className="highlighted-content">
          <p>Our most popular plan</p>
        </div>
      )}
      <div className="plan-header">
        <div className="plan-header-eero">
          <h2 className="header3">
            <span dangerouslySetInnerHTML={{ __html: name }} />
          </h2>
        </div>

        <p className="blurb">{blurb}</p>

        <p className="mt-3 mb-0">{typicalEveningSpeeds}</p>
      </div>
      <div className="plan-body">
        <div
          className={`price-circle price-circle-plan-table-new
            ${promotionalPricing && 'price-circle-promo'}`}
        >
          <div>
            <div className="amount">
              $<span>{price.toString().split('.')[0]}</span>.
              {price.toString().split('.')[1]}
            </div>
            {promotionalPricing && promotionalPricing}
            {!promotionalPricing && (
              <span className="text-bold-secondary">Per Month</span>
            )}
          </div>
        </div>
        <p className="minimum-cost">
          Minimum total cost ${minimumTotalCost}
          {optionalPlanCostCopy && (
            <>
              <br />
              <span className="plan-cost-copy">
                Upfront connection fee of $99
              </span>
            </>
          )}
        </p>
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/pdf/sales-critical-information-summary-nbn+voice.pdf"
          >
            Critical Information Summary (PDF)
          </a>
        </p>
        <p>
          <a target="_blank" href="/pdf/nbn-key-facts-sheet.pdf">
            NBN Key Facts Sheet
          </a>
        </p>
        <button
          type="button"
          href="sign-up"
          onClick={e => navigate(`/sign-up/?pl=${e.currentTarget.dataset.id}`)}
          data-id={id}
          className="btn btn-gradient btn-block"
        >
          Get Connected
        </button>
      </div>
    </div>
  </div>
)

Plan.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  banner: PropTypes.bool,
  highlighted: PropTypes.bool,
  blurb: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  promotionalPricing: PropTypes.node,
  minimumTotalCost: PropTypes.string.isRequired,
  optionalPlanCostCopy: PropTypes.string.isRequired,
  cisUrl: PropTypes.string.isRequired,
  typicalEveningSpeeds: PropTypes.string.isRequired,
}

Plan.defaultProps = {
  banner: false,
  highlighted: false,
  promotionalPricing: null,
}
