import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export const Collapsible = props => {
  const [state, setstate] = useState({
    collapsed: true,
  })

  function handleClick() {
    const { collapsed } = state
    setstate({ ...state, collapsed: !collapsed })
  }

  const { collapsed } = state
  const { children, title } = props

  return (
    <div className={`card card-collapsible ${collapsed ? 'collapsed' : ''}`}>
      <div className="card-header bg-transparent bb-0" onClick={handleClick}>
        <h3>{title}</h3>
      </div>
      <SlideDown className="card-content">
        {!collapsed ? <div className="card-body">{children}</div> : null}
      </SlideDown>
    </div>
  )
}

Collapsible.defaultProps = {
  children: null,
  collapsed: true,
  title: '',
}

Collapsible.props = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  collapsed: PropTypes.bool,
  title: PropTypes.string,
}
